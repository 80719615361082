import { ThunkAction } from "redux-thunk";

import {
  SignUpData,
  LogInData,
  LOG_OUT,
  SET_ERROR,
  SET_SUCCESS,
  AuthAction,
  SET_USER,
  SET_LOADING,
  SET_GLOBAL_LOADING,
  SET_LOGIN_SUCCESS,
} from "../types/auth";
import { RootState } from "..";
import toast from "react-hot-toast";

import { emailRegex, phoneRegex } from "../../utils/const";
import axios from "axios";
import { translations } from "../../utils/translations";
import { isValidPassword } from "../../utils/passwordUtil";
import { getToastMessage } from "../../utils/helper";

// Set loading
export const setLoading = (
  value: boolean
): ThunkAction<void, RootState, null, AuthAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: value,
    });
  };
};

// Set Global loading
export const setGlobalLoading = (
  value: boolean
): ThunkAction<void, RootState, null, AuthAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_GLOBAL_LOADING,
      payload: value,
    });
  };
};

// Setup config/headers and token
export const tokenConfig = () => {
  const token = localStorage.getItem("token");

  // Headers
  const config: any = {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
    },
  };

  // If token, add it to headers
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

// Sign Up
export const Register = (
  data: SignUpData
): ThunkAction<void, RootState, null, AuthAction> => {
  return async (dispatch) => {
    const savedLang = localStorage.getItem("language");
    const content: any =
      savedLang === "ar" ? translations["ar"] : translations["en"];
    const toastId = toast.loading(content["loading"]);
    try {
      // Headers
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const body: any = data;
      const formData = new FormData();

      if (!isValidPassword(data.password)) {
        toast.error(content["invalid_pass_format"], {
          id: toastId,
        });
      } else if (data.password !== data.confirmPassword) {
        toast.error(content["pass_match"], {
          id: toastId,
        });
      } else if (!emailRegex.test(data.email)) {
        toast.error(content["invalid_email_format"], {
          id: toastId,
        });
      } else if (!phoneRegex.test(data.phoneNumber)) {
        toast.error(content["invalid_phone_format"], {
          id: toastId,
        });
      } else {
        //TODO
        // Check if User Exist
        // toast.error(savedLang === "ar" ? "عنوان البريد الإلكتروني قيد الاستخدام، يرجى تجربة عنوان آخر!" : "Email Address already in use, please try another one!", {
        //     id: toastId,
        // });
        for (const property in body) {
          if (body[property] !== null && body[property] !== "")
            formData.append(property, body[property]);
        }
        axios
          .post("/api/v0.1/auth/register", formData, config)
          .then(async (res: any) => {
            toast.success(content[res.message] || content["signup_success"], {
              id: toastId,
            });
            dispatch(login({ email: data.email, password: data.password }));
          })
          .catch((err: any) =>
            toast.error(
              err.response.data?.error
                ? content[err.response.data?.error] || err.response.data?.error
                : content["unknown_error"],
              {
                id: toastId,
              }
            )
          );
      }
    } catch (err: any) {
      toast.error(getToastMessage(err, savedLang as "en" | "ar"), {
        id: toastId,
      });
    }
  };
};

// Log in
export const login = (
  data: LogInData
): ThunkAction<void, RootState, null, AuthAction> => {
  return async (dispatch) => {
    const savedLang = localStorage.getItem("language");
    const content: any =
      savedLang === "ar" ? translations["ar"] : translations["en"];
    const toastId = toast.loading(content["loading"]);
    try {
      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // Request body
      const body = JSON.stringify(data);
      axios
        .post("/api/v0.1/auth/authenticate", body, config)
        .then(async (res: any) => {
          toast.success(content["login_success"], {
            id: toastId,
          });
          dispatch({
            type: SET_LOGIN_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err: any) => {
          toast.error(getToastMessage(err, savedLang as "en" | "ar"), {
            id: toastId,
          });
        });
    } catch (err: any) {
      return toast.error(getToastMessage(err, savedLang as "en" | "ar"), {
        id: toastId,
      });
    }
  };
};

// Log out
export const logout = (): ThunkAction<void, RootState, null, AuthAction> => {
  return async (dispatch) => {
    const savedLang = localStorage.getItem("language");
    const toastId = toast.loading(
      savedLang === "ar" ? "جاري التحميل..." : "Loading..."
    );
    try {
      //TODO
      // Log User out
      dispatch({
        type: LOG_OUT,
      });

      toast.success(
        savedLang === "ar" ? "تم تسجيل الخروج" : "Logged out successfully",
        {
          id: toastId,
        }
      );
    } catch (err: any) {
      toast.error(getToastMessage(err, savedLang as "en" | "ar"), {
        id: toastId,
      });
    }
  };
};

// Set error
export const setError = (
  msg: string
): ThunkAction<void, RootState, null, AuthAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_ERROR,
      payload: msg,
    });
  };
};

// Set success
export const setSuccess = (
  msg: string
): ThunkAction<void, RootState, null, AuthAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: msg,
    });
  };
};

// Set error
export const setUser = (
  user: any
): ThunkAction<void, RootState, null, AuthAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      payload: user,
    });
  };
};

// Check token & load user
export const loadUser = (): ThunkAction<void, RootState, null, AuthAction> => {
  return async (dispatch) => {
    try {
      dispatch(setGlobalLoading(true));
      axios
        .get("/api/v0.1/auth/current", tokenConfig())
        .then((res: any) => {
          const token = localStorage.getItem("token");
          if (
            token === res.data.access_token &&
            res.data.role !== "driver" &&
            res.data.role !== "independentDriver"
          )
            dispatch(setUser(res.data));
          else logout();
          dispatch(setGlobalLoading(false));
        })
        .catch((err) => dispatch(setGlobalLoading(false)));
    } catch (err) {
      dispatch(setGlobalLoading(false));
    }
  };
};
